.tooltip {
    position: absolute; 
    background-color: white; 
    padding-top: 0.25rem; 
    padding-bottom: 0.25rem; 
    padding-left: 0.25rem; 
    padding-right: 0.25rem; 
    border-radius: 0.375rem; 
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); 
    visibility: hidden; 
    z-index: 10;
}