.download-button{
    font-family: 'Poppins'; 
    margin-top: 1rem; /* mt-24 */
    transition: all 0.3s ease-in-out;
    transform: scale(1);
    margin-left: auto; /* mx-auto */
    margin-right: auto; /* mx-auto */
    margin-bottom: 1rem; /* my-8 */
    background-color: #179C7D; /* bg-[#179C7D] */
    background-image: linear-gradient(to bottom left, #179C7D, #008598); /* bg-gradient-to-bl */
    cursor: pointer;
    color: #E5E5E5; /* text-gray-200 */
    font-weight: 600; /* font-semibold */
    padding: 0.5rem 2rem; /* py-2 px-8 */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* shadow-lg */
    border-radius: 0.75rem; /* rounded-xl */
    text-align: center;
}
.download-button:hover {
    transform: translateY(-0.25rem) scale(1.05); /* hover:scale-105 hover:translate-y-1 */
    background-color: #E5E5E5; /* hover:bg-gray-200 */
    border-color: transparent; /* hover:border-transparent */
}
.layout-button {
    margin-top: 1rem; 
    background-color: transparent;
    cursor: pointer;
    color: #005B7F;
    font-weight: 600; /* font-semibold */
    padding: 0.5rem 1rem; /* py-2 px-4 */
    border: 2px solid #005B7F;
    border-radius: 0.75rem; /* rounded-xl */
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.layout-button:hover {
    background-color: #005B7F;
    color: white;
    border-color: transparent;
}