.MuiPaper-root {
    border: 2px solid #005B7F !important;
    background-color: transparent !important;
    border-radius: 0.75rem !important;

}

.MuiTableHead-root, .MuiTablePagination-root {
    font-weight: bold !important;
    background-color: #005B7F !important;
    color: white !important;
}


.MuiTableCell-root, .MuiTableHead-root, .MuiTablePagination-displayedRows {
    font-family: 'Poppins', sans-serif !important;
}

.MuiTableCell-header {
    color: white !important;

}

.MuiCheckbox-colorPrimary {
    color: #005B7F !important;
}

.MuiCheckbox-colorSecondary {
    color: white !important;
}

.best-id-row {
    background-color: #179C7D !important;
}
